/*!------------------------------------------------------------------
[Off Canvas Cart]
*/

$off-canvas-bg: #000;
$off-canvas-cart-info-bg: #f8f8f8;

body {
	&:after {
		background: rgba($off-canvas-bg, 0.7);
		position: fixed;
		height: 1px;
		width: 1px;
		bottom: 0;
		right: 0;
		left: 0;
		top: -1px;
		z-index: 1035;
		content: " ";
		visibility: hidden;
		opacity: 0;
		-webkit-transition: opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		transition: opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		cursor: pointer;
	}
	&.off-canvas-cart-open:after {
		visibility: visible;
		height: 100%;
		width: 100%;
		opacity: 1;
		top: 0;
	}
}
.off-canvas-cart {
	background: $pure-white;
	position: fixed;
	right: -425px;
	top: 0;
	height: 100%;
	width: 425px;
	z-index: 1040;
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition: -webkit-transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86),
	-webkit-transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.off-canvas-cart-header {
	text-align: right;
	padding: 22px 50px;
	> a {
		font-size: 21px;
	}
}
.off-canvas-cart-wrapper {
	width: auto;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}
.off-canvas-cart-content-wrap {
	padding: 0 50px 265px;
}
.off-canvas-cart-item {
	display: table;
	padding: 20px 0;
	vertical-align: auto;
	border-bottom-color: $main-border-color;
	border-bottom-style: $main-border-style;
	border-bottom-width: $main-border-width;
	&:first-child {
		border-top-color: $main-border-color;
		border-top-style: $main-border-style;
		border-top-width: $main-border-width;
	}
	&:last-child {
		border: 0;
	}
}
.off-canvas-cart-item-title {
	display: table-cell;
	vertical-align: middle;
	padding: 0 0 0 20px;
	width: 66%;
}
.off-canvas-cart-item-trash {
	display: table-cell;
	vertical-align: middle;
	width: 12%;
}
.off-canvas-cart-item-thumbnail {
	display: table-cell;
	vertical-align: middle;
	width: 22%;
	img {
		width: 100%;
	}
}
.off-canvas-cart-footer {
	background: $pure-white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
}
.off-canvas-cart-info {
	background: $off-canvas-cart-info-bg;
	padding: 20px 50px;
}
.off-canvas-cart-control {
	padding: 50px;
	.btn:not(:last-child) {
		margin-bottom: 15px;
	}
}
@media(max-width: 736px) {
	.off-canvas-cart-content-wrap {
		padding-bottom: 20px;
	}
	.off-canvas-cart-footer {
		position: static;
	}
}
@media(max-width: 425px) {
	.off-canvas-cart {
		right: -100%;
		width: 100%;
	}
	.off-canvas-cart-header {
		padding-right: 20px;
		padding-left: 20px;
	}
	.off-canvas-cart-content-wrap {
		padding-right: 20px;
		padding-left: 20px;
	}
	.off-canvas-cart-control {
		padding-right: 20px;
		padding-left: 20px;
	}
	.off-canvas-cart-open .off-canvas-cart {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
.off-canvas-cart-open .off-canvas-cart {
	-webkit-transform: translateX(-425px);
	transform: translateX(-425px);
}

/*------------------------------------------------------------------
[Tables]
*/

$table-border-color: #eee;

$table-th-color: #222;
$table-th-font-weight: 600;

.table {
	> thead > tr {
		> th {
			border-bottom: 1px solid $table-border-color;
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
		> td {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
	}
	> tbody > tr {
		> th {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
		> td {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
	}

	> tbody > tr:last-child {
		> td {
			border-bottom: 1px solid $table-border-color;
		}
	}


	> tbody > tr {
		> th {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
		> td {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
		> td:last-child {
			border-bottom: 1px solid $table-border-color;
		}
	}
	> tfoot > tr {
		> th {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
		> td {
			border-top: 1px solid $table-border-color;
			padding: 10px;
		}
	}
}
.table-bordered {
	border: 1px solid $table-border-color;
	> thead > tr {
		> th {
			border-color: $table-border-color;
		}
		> td {
			border-color: $table-border-color;
		}
	}
	> tbody > tr {
		> th {
			border-color: $table-border-color;
		}
		> td {
			border-color: $table-border-color;
		}
	}
	> tfoot > tr {
		> th {
			border-color: $table-border-color;
		}
		> td {
			border-color: $table-border-color;
		}
	}
}
@media(max-width: $screen-xs-max) {
	.table-responsive {
		border: 0;
		margin-bottom: 0;
	}
}
table th {
	font-weight: $table-th-font-weight;
	color: $table-th-color;
}
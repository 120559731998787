/*------------------------------------------------------------------
[Gallery]
*/

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.gallery {
	margin-left: -4px;
	margin-right: -4px;
	.gallery-item {
		padding: 0 4px 8px;
		-webkit-transition: $main-custom-transition;
		transition: $main-custom-transition;
		&.gallery-item-big {
			width: 100% !important;
		}
		&:hover {
			opacity: 0.7;
		}
		a {
			position:relative;
			display: block;
		}
		.gallery-caption {
			position: absolute;
			width:100%;
			padding: 1rem;
			left: 0;
			bottom: 0;
			font-size: 1.5rem; ;
			color: #FFFFFF;
			background-color: rgba(0, 0, 0, 0.5);
		}

	}
}
$main-inner-bg-color: $pure-white;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.main {

	.main-inner {
		max-width: $screen-lg-min;
		margin: 0 auto;
		background-color: $main-inner-bg-color;
	}

}

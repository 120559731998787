/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@import "colors";

@import "lara5/mixins/lara-mixins";

@import "lara5/general";
@import "lara5/main";
@import "lara5/margin-padding";
@import "lara5/typography";
@import "lara5/alert";
@import "lara5/progress-bar";
@import "lara5/label";
@import "lara5/form";
@import "lara5/table";
@import "lara5/button";
@import "lara5/breadcrumb";
@import "lara5/icon-box";
@import "lara5/icon-preview";
@import "lara5/screenshot";
@import "lara5/off-canvas";
@import "lara5/preloader";
@import "lara5/dropdown";
@import "lara5/counter";
@import "lara5/team";
@import "lara5/tabs-acc";
@import "lara5/testimonial";
@import "lara5/pagination";
@import "lara5/feature";
@import "lara5/map";
@import "lara5/client";
@import "lara5/pricing";
@import "lara5/carousel";
@import "lara5/social-icon";
@import "lara5/top-bar";
@import "lara5/header";
@import "lara5/header-search";
@import "lara5/modules";
@import "lara5/hero";
@import "lara5/background";
@import "lara5/multi-column";
@import "lara5/row-as-table";
@import "lara5/page";
@import "lara5/portfolio";
@import "lara5/blog";
// @import "lara5/shop";
@import "lara5/review";
@import "lara5/widgets";
@import "lara5/widgets-home";
@import "lara5/gallery";
@import "lara5/footer";

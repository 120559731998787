/*------------------------------------------------------------------
[Footer]
*/

// Footer
$footer-color:                    #eee;
$footer-bg-color: #0a0f14;
$footer-border-color:             #3c4650;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.footer {
	background-color: $footer-bg-color;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding: 70px 0 0;

	ul {
		margin:0 0 20px 0;
		padding:0;
		list-style: none;
	}
	.widget-title {
		border-color: $footer-border-color;
	}
	.copyright {
		border-top: 1px solid $footer-border-color;
		padding: 20px 0;
		margin: 35px 0 0;
	}
	.subfooter {
		border-top: 1px solid $pure-white;
		padding-top: 70px;
		margin-top: 70px;
	}
	&.parallax {
		background-color: transparent;
	}
	* {
		color: $footer-color;
	}
	a {
		text-decoration: none ;
		&:hover {
			text-decoration: underline;
			color: inherit;
		}
		&:focus {
			text-decoration: underline;
			color: inherit;
		}
	}
	.list-inline {
		margin-left: -15px;
		> li {
			padding-left: 15px;
			padding-right: 15px;
			a {
				text-decoration: none;
			}
		}
	}
	/*
	.bottom-line:before {
		border-bottom: 2px solid $footer-border-color;
	}
	*/
	.bottom-line:after {
		border-bottom: 1px solid $footer-border-color;
	}
	.widget .recent-posts > li {
		border-color: $footer-border-color;
	}
}
.scroll-top {
	border-radius: $brand-sitewide-radius;
	border: 2px solid $pure-white;
	padding: 6px 13px;
	font-size: 18px;
	opacity: 0.5;
}
.footer-minimal {
	padding: 140px 0;
}
@media(max-width: $screen-xs-max) {
	.footer .scroll-top {
		display: none;
	}
}
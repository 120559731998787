/*------------------------------------------------------------------
[Icon box]
*/

$box-icon-color: $brand-primary;

.box-icon {
	padding: 35px 0;
	.icon-box-icon {
		font-size: 34px;
		color: $box-icon-color;
		margin: 0 0 10px;
	}
	p:last-child {
		margin: 0;
	}
}
.box-icon-left {
	padding: 35px 0;
	position: relative;
	padding: 35px 0 35px 50px;
	.icon-box-icon {
		font-size: 34px;
		color: $box-icon-color;
		margin: 0 0 10px;
		position: absolute;
		height: 44px;
		width: 30px;
		left: 0;
		top: 35px;
		text-align: center;
		line-height: 40px;
		font-size: 26px;
	}
	p:last-child {
		margin: 0;
	}
}
.box-icon-right {
	padding: 35px 0;
	position: relative;
	padding: 35px 50px 35px 0;
	text-align: right;
	.icon-box-icon {
		font-size: 34px;
		color: $box-icon-color;
		margin: 0 0 10px;
		position: absolute;
		height: 44px;
		width: 30px;
		right: 0;
		top: 35px;
		text-align: center;
		line-height: 40px;
		font-size: 26px;
	}
	p:last-child {
		margin: 0;
	}
}
@media(max-width: $screen-xs-max) {
	.box-icon-left {
		padding: 35px 0 35px 50px;
		text-align: left;
		.icon-box-icon {
			left: 0;
			top: 35px;
		}
	}
	.box-icon-right {
		padding: 35px 0 35px 50px;
		text-align: left;
		.icon-box-icon {
			left: 0;
			top: 35px;
		}
	}
}
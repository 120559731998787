/*------------------------------------------------------------------
[Icon Preview]
*/

$fa-icons-border-color: #f5f5f5;

.icon-preview {
	display: block;
	width: 25%;
	float: left;
	padding: 10px;
	font-size: 13px;
	margin: -1px 0 0 -1px;
	border-color: $main-border-color;
	border-style: $main-border-style;
	border-width: $main-border-width;
	.icons {
		margin-right: 5px;
	}
}
.fa-icons > div {
	border: 1px solid $fa-icons-border-color;
	margin: -1px 0 0 -1px;
	padding: 0;
	font-size: 13px;
	> i {
		display: inline-block;
		margin-right: 5px;
		min-width: 40px;
		min-height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 14px;
		border-color: $main-border-color;
		border-style: $main-border-style;
		border-width: $main-border-width;
	}
}
/*------------------------------------------------------------------
[Dropdown]
*/

$dropdown-menu-bg: #222;
$dropdown-menu-divider-bg: #444;
$dropdown-menu-li-a-color: #fff;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.dropdown-menu {
	background-color: $dropdown-menu-bg;
	border: 0;
	border-radius: $brand-sitewide-radius;
	box-shadow: none;
	min-width: 180px;
	font-size: 12px;
	padding: 10px 0;
	z-index: 1031;

	.divider {
		background: $dropdown-menu-divider-bg;
		margin: 10px 0;
	}
	li > a {
		background: none !important;
		padding: 10px 24px 10px 20px;
		color: rgba($dropdown-menu-li-a-color, 0.5);
		&:hover {
			color: $dropdown-menu-li-a-color;
		}
		&:focus {
			color: $dropdown-menu-li-a-color;
		}
	}
	.open > a {
		color: $dropdown-menu-li-a-color;
		&:hover {
			color: $dropdown-menu-li-a-color;
		}
		&:focus {
			color: $dropdown-menu-li-a-color;
		}
	}
}
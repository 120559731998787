/*------------------------------------------------------------------
[Pages]
*/

$career-tags-color: $brand-primary;

.career-tags {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	color: $career-tags-color;
	list-style: none;
	padding: 0;
}

.career-tags > li {
	display: inline-block;
}

@media (max-width: $screen-xs-max) {
	.page-content + .sidebar,
	.sidebar + .page-content {
		margin: 70px 0 0;
	}
}


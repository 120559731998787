//
// Buttons
// --------------------------------------------------

// Button styles
$btn-text-transform:              none;
$btn-letter-spacing:              1px;
$btn-font-weight:                 normal;
$btn-font-size:                   14px;
$btn-border-width:                2px;
$btn-border-radius:               $brand-sitewide-radius;
$btn-transition:                  all 0.2s ease-out;

$btn-lg-font-size:                16px;
$btn-sm-font-size:                12px;

// Extra Button colors
$btn-brand1-color:                $pure-white;
$btn-brand1-bg:                   $brand1;
$btn-brand1-border:               $brand1;

$btn-brand2-color:                $pure-white;
$btn-brand2-bg:                   $brand2;
$btn-brand2-border:               $brand2;

$btn-brand3-color:                $pure-white;
$btn-brand3-bg:                   $brand3;
$btn-brand3-border:               $brand3;

$btn-brand4-color:                $pure-white;
$btn-brand4-bg:                   $brand4;
$btn-brand4-border:               $brand4;

$btn-brand5-color:                $pure-white;
$btn-brand5-bg:                   $brand5;
$btn-brand5-border:               $brand5;

$btn-dark-color:                  $pure-white;
$btn-dark-bg:                     $grey-800;
$btn-dark-border:                 $grey-800;

$btn-gray-color:                  $grey-700;
$btn-gray-bg:                     $grey-100;
$btn-gray-border:                 $grey-100;

$btn-white-color:                 $grey-700;
$btn-white-bg:                    $pure-white;
$btn-white-border:                $pure-white;




/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */


/* --- [Override default button styles] --- */

.btn-default {
	@include btn-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
	@include btn-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-success {
	@include btn-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-info {
	@include btn-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.btn-warning {
	@include btn-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-danger {
	@include btn-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

/* --- [Create extra button styles] --- */

.btn-base {
	@include btn-variant($btn-brand1-color, $btn-brand1-bg, $btn-brand1-border);
}

.btn-brand1 {
	@include btn-variant($btn-brand1-color, $btn-brand1-bg, $btn-brand1-border);
}

.btn-brand2 {
	@include btn-variant($btn-brand2-color, $btn-brand2-bg, $btn-brand2-border);
}

.btn-brand3 {
	@include btn-variant($btn-brand3-color, $btn-brand3-bg, $btn-brand3-border);
}

.btn-brand4 {
	@include btn-variant($btn-brand4-color, $btn-brand4-bg, $btn-brand4-border);
}

.btn-brand5 {
	@include btn-variant($btn-brand5-color, $btn-brand5-bg, $btn-brand5-border);
}

.btn-gray {
	@include btn-variant($btn-gray-color, $btn-gray-bg, $btn-gray-border);
}

.btn-dark {
	@include btn-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}

.btn-white {
	@include btn-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}



.btn {
	text-transform: $btn-text-transform;
	letter-spacing: $btn-letter-spacing;
	font-weight: $btn-font-weight;
	font-size: $btn-font-size;
	border-width: $btn-border-width;
	border-radius: $btn-border-radius;
	-webkit-transition: $btn-transition;
	transition: $btn-transition;
	&:focus {
		outline: 0;
	}
	&.focus {
		outline: 0;
	}
	&.active {
		&.focus {
			outline: 0;
		}
		&:focus {
			outline: 0;
		}
	}
	&:active {
		&.focus {
			outline: 0;
		}
		&:focus {
			outline: 0;
		}
	}
	&.navbar-btn {
		font-weight: bold !important;
		font-size: 10px;
		padding: 6px 20px !important;
		margin: 24px 15px;
	}
}

.btn-upper {
	text-transform: uppercase;
}

.btn-lower {
	text-transform: lowercase;
}

.btn-lg {
	padding: 13px 31px;
	font-size: $btn-lg-font-size;
}
.btn-sm {
	padding: 9px 19px;
	font-size: $btn-sm-font-size;
}
.btn-xs {
	padding: 8px 18px;
	font-size: 10px;
}
.btn-list .btn {
	margin-top: 5px;
	margin-bottom: 5px;
}
.header-small .btn.navbar-btn {
	margin: 17px 15px;
}
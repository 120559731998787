/*------------------------------------------------------------------
[Screenshots]
*/

$screenshot-bg: #f8f8f8;

.screenshots .screenshot {
	background: $screenshot-bg;
	border-radius: $brand-sitewide-radius;
	padding: 10px;
	margin: 0 0 30px;
	img {
		border-radius: $brand-sitewide-radius;
		width: 100%;
	}
}

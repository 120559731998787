.g-recaptcha {
	display: inline-block; // needed for centering
}

.widget .events li.even,
.widget .recent-posts li.even {
	padding-left:10px;
	background-color: #fff;
	color:#222;
	h6, h6 a {
		font-family: $font-family-base;
		font-weight: 400;
		color:#222;
	}
}

.widget .events li.odd,
.widget .recent-posts li.odd {
	padding-left:10px;
	background-color: #D32F2F;
	color:#fff;
	h6, h6 a {
		font-family: $font-family-base;
		font-weight: 400;
		color:#fff;
	}
	h6 {
		margin:0;
	}
}

.widget .events li {
	h6 {
		margin:0;
		line-height:1.8;
	}
}

.widget .recent-posts > li:first-child {
	padding-top: 10px;
}

.widget .events > li:first-child {
	padding-top: 10px;
}

.widget-slider.owl-carousel .owl-item {
	-webkit-animation-duration: 2s !important;
	animation-duration: 2s !important;
}

@media(min-width: $screen-md-min) {
	.home-widget-text {
		min-height: 125px;
	}
	.home-widget-text p {
		margin: 0;
	}
}



.widget h4 {
	color: #F57C00;
}

.bg-blue-alfa-80:before {
	background-color: rgba($brand-primary, 0.8);
}
/*------------------------------------------------------------------
[Header & Navigation]
*/

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Header
$header-color:                    #444;
$header-bg-color:                 rgba(255,255,255,0.9);
$header-border-color:             #333;
$header-box-shadow:               inset 0 -1px 0px #f5f5f5;

// Transparency
$header-semi-trans-bg-color:     rgba(255,255,255,0.8);



// Menu (large medium)
$menu-bg-color:                   transparent;
$menu-color:                      #444;
$menu-color-hover:                $brand-danger;
$menu-color-active:               $brand-primary;

// Submenu (large medium)
$submenu-bg-color:                rgba(255,255,255,0.9);
$submenu-color:                   #444;
$submenu-bg-color-hover:          $teal-700;
$submenu-color-hover:             #fff;
$submenu-title-color:             #fff;
$submenu-border-color:            rgba(255,255,255, 0.1);
$submenu-border-style:            solid;
$submenu-border-width:            1px;

// Menu (small)
$menu-small-bg-color:             $grey-100;
$menu-small-color:                #444;
$menu-small-color-hover:          $blue-900;
$menu-small-color-active:         $red-700;

// Submenu (small collapsed)
$submenu-small-bg-color:          $grey-100;
$submenu-small-color:             #999;
$submenu-small-bg-color-hover:    none;
$submenu-small-color-hover:       #222;
$submenu-small-border-color:      #f5f5f5;
$submenu-small-border-style:      solid;
$submenu-small-border-width:      1px;

// Nav icon toggle
$nav-icon-toggle-color:           #222;

// Menu main border
$menu-main-border-color:          #f5f5f5;
$menu-main-border-style:          solid;
$menu-main-border-width:          1px;

// Mega menu
$submenu-mega-li-border-color:    #333;

// Menu extras
$menu-extras-box-shadow:          #f5f5f5;

// Padding
$mainmenu-padding-tb:             33px;
$mainmenu-padding-lr:             12px;

// Font sizes
$inner-brand-font-size:           18px;

$mainmenu-font-family:            "Roboto", Helvetica, Arial, serif;
$mainmenu-font-size:              16px;
$mainmenu-font-weight:            400;
$mainmenu-line-height:            20px;
$mainmenu-text-transform:         none;
$mainmenu-letter-spacing:         1px;

$submenu-font-size:               13px;
$submenu-title-font-size:         13px;
$menu-extras-font-size:           18px;


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.header {
	background: $header-bg-color;
	color: $header-color;
	position: relative;
	z-index: 1030;
	box-shadow: $header-box-shadow;
	-webkit-transition: height 0.3s ease-out, background 0.3s ease-out, box-shadow 0.3s ease-out;
	transition: height 0.3s ease-out, background 0.3s ease-out, box-shadow 0.3s ease-out;
	&.header-fixed {
		position: fixed;
		z-index: 1030;
		right: 0;
		left: 0;
	}
}
.inner-header {
	position: relative;
	z-index: 1;
	float: left;
}
.inner-brand {
	display: table-cell;
	vertical-align: middle;
	height: 86px;
	font-weight: 700;
	font-size: $inner-brand-font-size;
	color: $header-color;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	.brand-light {
		display: none;
	}
	img {
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}
}

.menu-extras {
	padding: 0 0 0 15px;
	margin: 0 0 0 15px;
	box-shadow: -1px 0 0 $menu-extras-box-shadow;
	-webkit-transition: box-shadow 0.3s ease-out 0.125s;
	transition: box-shadow 0.3s ease-out 0.125s;
	.menu-item {
		float: left;
	}
	> .menu-item > div > a {
		position: relative;
		display: block;
		line-height: 20px;
		font-size: $menu-extras-font-size;
		color: $menu-color;
		padding: 33px 15px;
		&:hover {
			color: $menu-color-hover;
		}
		&:focus {
			color: $menu-color-hover;
		}
	}
}
.cart-badge {
	position: absolute;
	background: $brand-primary;
	border-radius: 50%;
	height: 14px;
	width: 14px;
	top: 50%;
	left: 23px;
	margin-top: -13px;
	text-align: center;
	line-height: 14px;
	font-weight: bold;
	font-size: 8px;
	color: $pure-white;
}
.main-nav {
	position: relative;
}
.inner-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	> li {
		float: left;
		> a {
			text-transform: $mainmenu-text-transform;
			letter-spacing: $mainmenu-letter-spacing;
			line-height: $mainmenu-line-height;
			font-family: $mainmenu-font-family;
			font-weight: $mainmenu-font-weight;
			font-size: $mainmenu-font-size;
			display: block;
			padding: $mainmenu-padding-tb $mainmenu-padding-lr;
			color: $menu-color;
			&.active {
				color: $menu-color-active;
			}
			&:hover {
				color: $menu-color-hover;
			}
			&:focus {
				color: $menu-color-hover;
			}
		}
		&.active > a {
			color: $red-700;
		}
		&.submenu-open > a {
			color: $menu-color-hover;
		}
	}
}
.submenu {
	list-style: none;
	padding: 0;
	margin: 0;
	position: absolute;
	background: $submenu-bg-color;
	padding: 0;
	min-width: 180px;
	opacity: 0;
	z-index: 1030;
	visibility: hidden;
	text-align: left;
	font-size: $submenu-font-size;
	.submenu {
		left: 100%;
		top: 0;
	}
	li {
		position: relative;
		a {
			position: relative;
			display: block;
			line-height: 1.4;
			padding: 12px 27px 12px 20px;
			border-bottom-color: $submenu-border-color;
			border-bottom-style: $submenu-border-style;
			border-bottom-width: $submenu-border-width;
			> {
				.fa {
					margin-right: 8px;
				}
				.icons {
					margin-right: 8px;
				}
			}
		}
		> a {
			color: $submenu-color;
			&:hover {
				background: $submenu-bg-color-hover;
				color: $submenu-color-hover;
			}
			&:focus {
				background: $submenu-bg-color-hover;
				color: $submenu-color-hover;
			}
		}
		&:last-child > a {
			border: 0;
		}
		&.submenu-open > a {
			background: $submenu-bg-color-hover;
			color: $submenu-color-hover;
		}
	}
	.has-submenu > a:after {
		position: absolute;
		content: "\f105";
		display: block;
		right: 14px;
		top: 50%;
		margin-top: -7px;
		font-family: FontAwesome;
		text-rendering: auto;
		font-size: 12px;
		font-weight: bold;
	}
}
.submenu-mega {
	display: table;
	width: 100%;
	padding: 0;
	right: 0;
	left: 0;
	> li {
		display: table-cell;
		padding: 15px 10px;
		width: 25%;
		> ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
		&:not(:last-child) {
			border-right: 1px solid rgba($submenu-mega-li-border-color, 0.5);
		}
	}
	li a {
		border: 0;
	}
}
.submenu-title {
	position: relative;
	display: block;
	line-height: 1.4;
	padding: 12px 27px 12px 20px;
	border-bottom-color: $submenu-border-color;
	border-bottom-style: $submenu-border-style;
	border-bottom-width: $submenu-border-width;
	border: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 1.8;
	font-weight: 700;
	font-size: $submenu-title-font-size;
	color: $submenu-title-color;
}
.main-nav-toggle {
	display: none;
	float: right;
	padding: 33px 0 33px 15px;
	margin-left: 15px;
	border-left-color: $menu-main-border-color;
	border-left-style: $menu-main-border-style;
	border-left-width: $menu-main-border-width;
}
.nav-icon-toggle {
	position: relative;
	height: 20px;
	width: 20px;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	span {
		background: $nav-icon-toggle-color;
		position: absolute;
		display: block;
		height: 1px;
		width: 100%;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		&:nth-child(1) {
			top: 3px;
			-webkit-transform-origin: left center;
			transform-origin: left center;
		}
		&:nth-child(2) {
			top: 10px;
			-webkit-transform-origin: left center;
			transform-origin: left center;
		}
		&:nth-child(3) {
			top: 16px;
			-webkit-transform-origin: left center;
			transform-origin: left center;
		}
	}
	&.open span {
		&:nth-child(1) {
			top: 2px;
			left: 3px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&:nth-child(2) {
			opacity: 0;
			width: 0;
		}
		&:nth-child(3) {
			top: 16px;
			left: 3px;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
}
@media(max-width: $screen-sm-max) {
	.main-nav-toggle {
		display: block;
	}
	.main-nav {
		position: absolute;
		background: $menu-small-bg-color;
		max-height: 480px;
		width: 100%;
		left: 0;
		top: 100%;
		border-bottom-color: $menu-main-border-color;
		border-bottom-style: $menu-main-border-style;
		border-bottom-width: $menu-main-border-width;
		&.in {
			overflow-y: auto;
		}
	}
	.inner-nav {
		float: none !important;
		padding: 10px 15px;
		> li {
			display: block;
			float: none;
			> a {
				position: relative;
				padding: 10px 0;
				color: $menu-small-color;
				&.active {
					color: $menu-small-color-active;
				}
				&:hover {
					color: $menu-small-color-hover;
				}
				&:focus {
					color: $menu-small-color-hover;
				}
			}
			&:not(:last-child) > a {
				border-bottom-color: $menu-main-border-color;
				border-bottom-style: $menu-main-border-style;
				border-bottom-width: $menu-main-border-width;
			}
			&.submenu-open > a {
				color: $menu-small-color-hover;
			}
		}
	}
	.submenu {
		background: $submenu-small-bg-color;
		display: none;
		margin: 0;
		padding: 0 0 0 15px;
		a {
			border-bottom-color: $menu-main-border-color;
			border-bottom-style: $menu-main-border-style;
			border-bottom-width: $menu-main-border-width;
		}
		.submenu {
			background: $submenu-small-bg-color;
			display: none;
			margin: 0;
		}
		&.submenu-mega {
			background: $submenu-small-bg-color;
			display: none;
			margin: 0;
			li a:after {
				right: 0;
			}
		}
		li {
			a {
				padding: 10px 4px 10px 0;
			}
			> a {
				color: $submenu-small-color;
				border-bottom-color: $submenu-small-border-color;
				border-bottom-style: $submenu-small-border-style;
				border-bottom-width: $submenu-small-border-width;
				&:hover {
					background: $submenu-small-bg-color-hover;
					color: $submenu-small-color-hover;
				}
				&:focus {
					background: $submenu-small-bg-color-hover;
					color: $submenu-small-color-hover;
				}
			}
			&.submenu-open > a {
				background: $submenu-small-bg-color-hover;
				color: $submenu-small-color-hover;
			}
		}
		.has-submenu > a:after {
			right: 0;
		}
	}
	.submenu-title {
		padding: 15px 0 10px;
		background: $submenu-small-bg-color-hover;
		color: $submenu-small-color-hover;
	}
	.header .container {
		width: 100%;
	}
	.submenu-open > .submenu {
		position: static;
		display: block !important;
	}
	.submenu-mega > li {
		display: block;
		width: 100%;
		padding: 0;
		border: 0;
		&:not(:last-child) {
			border: 0;
		}
	}
	.has-submenu > a:after {
		position: absolute;
		content: "\f105";
		display: block;
		right: 0;
		top: 50%;
		margin-top: -9px;
		font-family: FontAwesome;
		text-rendering: auto;
		font-size: 12px;
		font-weight: bold;
	}
}
@media(min-width: $screen-md-min) {
	.header-small {
		.inner-brand {
			height: 72px;
			img {
				width:150px;
			}
		}
		.main-nav-toggle {
			padding-top: 26px;
			padding-bottom: 26px;
		}
		.inner-nav > li > a {
			padding-top: 26px;
			padding-bottom: 26px;
		}
		.menu-extras .menu-item a {
			padding-top: 26px;
			padding-bottom: 26px;
		}
	}
	.main-nav.collapse {
		display: block !important;
		overflow: visible !important;
		height: auto !important;
		padding-bottom: 0;
	}
	.header.header-transparent {
		background: transparent;
		box-shadow: inset 0 -1px 0 rgba($pure-white, 0.1);
		-webkit-transition: height 0.3s ease-out, background 0.3s ease-out, box-shadow 0s ease-out;
		transition: height 0.3s ease-out, background 0.3s ease-out, box-shadow 0s ease-out;
		.brand-light {
			display: inline;
		}
		.brand-dark {
			display: none;
		}
		.menu-extras {
			box-shadow: -1px 0 0 rgba($pure-white, 0.1);
			-webkit-transition: box-shadow 0s ease-out;
			transition: box-shadow 0s ease-out;
			.menu-item a {
				color: $pure-white;
				&:hover {
					color: rgba($pure-white, 0.7);
				}
				&:focus {
					color: rgba($pure-white, 0.7);
				}
			}
		}
		.inner-nav > li {
			> a {
				color: $pure-white;
				&:hover {
					color: rgba($pure-white, 0.7);
				}
				&:focus {
					color: rgba($pure-white, 0.7);
				}
			}
			&.active > a {
				color: rgba($pure-white, 0.7);
			}
			&.submenu-open > a {
				color: rgba($pure-white, 0.7);
			}
		}
	}
	.header.header-semi-transparent {
		background: $header-semi-trans-bg-color;
	}
}
.submenu-open > .submenu {
	visibility: visible;
	opacity: 1;
}

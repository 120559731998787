/*------------------------------------------------------------------
[Team]
*/

$team-social-a-color: #fff;

$team-item-white-team-social-a-color: #333;

$team-item-bg-hover: $brand-primary;
$team-item-dark-bg-hover: #333;
$team-item-white-bg-hover: #fff;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.team-item {
	text-align: center;
}

.team-photo {
	position: relative;
	overflow: hidden;
}

.team-photo > img {
	width: 100%;
}

.team-photo:after {
	background: transparent;
	position: absolute;
	display: block;
	content: " ";
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.team-social {
	position: absolute;
	padding: 20px;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	z-index: 2;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.team-social > div {
	display: table;
	height: 100%;
	width: 100%;
}

.team-social > div > div {
	vertical-align: middle;
	display: table-cell;
}

.team-social a {
	display: inline-block;
	padding: 0 15px;
	font-size: 24px;
	color: $team-social-a-color;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.team-social a:hover {
	opacity: 0.7;
}

.team-inner {
	margin: 25px 0;
}

.team-name {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
}

.team-role {
	font-size: 13px;
}


.team-item:hover .team-photo:after {
	background: rgba($team-item-bg-hover, 0.9);
}

.team-item:hover .team-social {
	opacity: 1;
}

/* --- [ Dark Hover Overlay ] --- */

.team-item.team-item-dark:hover .team-photo:after {
	background: rgba($team-item-dark-bg-hover, 0.9);
}

/* --- [ White Hover Overlay ] --- */

.team-item.team-item-white:hover .team-photo:after {
	background: rgba($team-item-white-bg-hover, 0.9);
}

.team-item-white .team-social a {
	color: $team-item-white-team-social-a-color;
}

/*------------------------------------------------------------------
[Maps]
*/

.maps-container {
	height: 450px;
}

#map {
	height: 100% !important;
	width: 100%;
}
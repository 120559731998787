@mixin generate-color($name, $color) {
	.clr-#{$name} {
		color: $color !important;
	}
	.bg-#{$name} {
		background-color: $color !important;
	}
}


// black
@include generate-color('black', $pure-black);

// white
@include generate-color('white', $pure-white);


// red
@include generate-color('red-50', $red-50);
@include generate-color('red-100', $red-100);
@include generate-color('red-200', $red-200);
@include generate-color('red-300', $red-300);
@include generate-color('red-400', $red-400);
@include generate-color('red-500', $red-500);
@include generate-color('red-600', $red-600);
@include generate-color('red-700', $red-700);
@include generate-color('red-800', $red-800);
@include generate-color('red-900', $red-900);

@include generate-color('red-A100', $red-A100);
@include generate-color('red-A200', $red-A200);
@include generate-color('red-A400', $red-A400);
@include generate-color('red-A700', $red-A700);

// pink
@include generate-color('pink-50', $pink-50);
@include generate-color('pink-100', $pink-100);
@include generate-color('pink-200', $pink-200);
@include generate-color('pink-300', $pink-300);
@include generate-color('pink-400', $pink-400);
@include generate-color('pink-500', $pink-500);
@include generate-color('pink-600', $pink-600);
@include generate-color('pink-700', $pink-700);
@include generate-color('pink-800', $pink-800);
@include generate-color('pink-900', $pink-900);

@include generate-color('pink-A100', $pink-A100);
@include generate-color('pink-A200', $pink-A200);
@include generate-color('pink-A400', $pink-A400);
@include generate-color('pink-A700', $pink-A700);


// purple
@include generate-color('purple-50', $purple-50);
@include generate-color('purple-100', $purple-100);
@include generate-color('purple-200', $purple-200);
@include generate-color('purple-300', $purple-300);
@include generate-color('purple-400', $purple-400);
@include generate-color('purple-500', $purple-500);
@include generate-color('purple-600', $purple-600);
@include generate-color('purple-700', $purple-700);
@include generate-color('purple-800', $purple-800);
@include generate-color('purple-900', $purple-900);

@include generate-color('purple-A100', $purple-A100);
@include generate-color('purple-A200', $purple-A200);
@include generate-color('purple-A400', $purple-A400);
@include generate-color('purple-A700', $purple-A700);


// deep-purple
@include generate-color('deep-purple-50', $deep-purple-50);
@include generate-color('deep-purple-100', $deep-purple-100);
@include generate-color('deep-purple-200', $deep-purple-200);
@include generate-color('deep-purple-300', $deep-purple-300);
@include generate-color('deep-purple-400', $deep-purple-400);
@include generate-color('deep-purple-500', $deep-purple-500);
@include generate-color('deep-purple-600', $deep-purple-600);
@include generate-color('deep-purple-700', $deep-purple-700);
@include generate-color('deep-purple-800', $deep-purple-800);
@include generate-color('deep-purple-900', $deep-purple-900);

@include generate-color('deep-purple-A100', $deep-purple-A100);
@include generate-color('deep-purple-A200', $deep-purple-A200);
@include generate-color('deep-purple-A400', $deep-purple-A400);
@include generate-color('deep-purple-A700', $deep-purple-A700);


// indigo
@include generate-color('indigo-50', $indigo-50);
@include generate-color('indigo-100', $indigo-100);
@include generate-color('indigo-200', $indigo-200);
@include generate-color('indigo-300', $indigo-300);
@include generate-color('indigo-400', $indigo-400);
@include generate-color('indigo-500', $indigo-500);
@include generate-color('indigo-600', $indigo-600);
@include generate-color('indigo-700', $indigo-700);
@include generate-color('indigo-800', $indigo-800);
@include generate-color('indigo-900', $indigo-900);

@include generate-color('indigo-A100', $indigo-A100);
@include generate-color('indigo-A200', $indigo-A200);
@include generate-color('indigo-A400', $indigo-A400);
@include generate-color('indigo-A700', $indigo-A700);


// blue
@include generate-color('blue-50', $blue-50);
@include generate-color('blue-100', $blue-100);
@include generate-color('blue-200', $blue-200);
@include generate-color('blue-300', $blue-300);
@include generate-color('blue-400', $blue-400);
@include generate-color('blue-500', $blue-500);
@include generate-color('blue-600', $blue-600);
@include generate-color('blue-700', $blue-700);
@include generate-color('blue-800', $blue-800);
@include generate-color('blue-900', $blue-900);

@include generate-color('blue-A100', $blue-A100);
@include generate-color('blue-A200', $blue-A200);
@include generate-color('blue-A400', $blue-A400);
@include generate-color('blue-A700', $blue-A700);


// light-blue
@include generate-color('light-blue-50', $light-blue-50);
@include generate-color('light-blue-100', $light-blue-100);
@include generate-color('light-blue-200', $light-blue-200);
@include generate-color('light-blue-300', $light-blue-300);
@include generate-color('light-blue-400', $light-blue-400);
@include generate-color('light-blue-500', $light-blue-500);
@include generate-color('light-blue-600', $light-blue-600);
@include generate-color('light-blue-700', $light-blue-700);
@include generate-color('light-blue-800', $light-blue-800);
@include generate-color('light-blue-900', $light-blue-900);

@include generate-color('light-blue-A100', $light-blue-A100);
@include generate-color('light-blue-A200', $light-blue-A200);
@include generate-color('light-blue-A400', $light-blue-A400);
@include generate-color('light-blue-A700', $light-blue-A700);


// cyan
@include generate-color('cyan-50', $cyan-50);
@include generate-color('cyan-100', $cyan-100);
@include generate-color('cyan-200', $cyan-200);
@include generate-color('cyan-300', $cyan-300);
@include generate-color('cyan-400', $cyan-400);
@include generate-color('cyan-500', $cyan-500);
@include generate-color('cyan-600', $cyan-600);
@include generate-color('cyan-700', $cyan-700);
@include generate-color('cyan-800', $cyan-800);
@include generate-color('cyan-900', $cyan-900);

@include generate-color('cyan-A100', $cyan-A100);
@include generate-color('cyan-A200', $cyan-A200);
@include generate-color('cyan-A400', $cyan-A400);
@include generate-color('cyan-A700', $cyan-A700);


// teal
@include generate-color('teal-50', $teal-50);
@include generate-color('teal-100', $teal-100);
@include generate-color('teal-200', $teal-200);
@include generate-color('teal-300', $teal-300);
@include generate-color('teal-400', $teal-400);
@include generate-color('teal-500', $teal-500);
@include generate-color('teal-600', $teal-600);
@include generate-color('teal-700', $teal-700);
@include generate-color('teal-800', $teal-800);
@include generate-color('teal-900', $teal-900);

@include generate-color('teal-A100', $teal-A100);
@include generate-color('teal-A200', $teal-A200);
@include generate-color('teal-A400', $teal-A400);
@include generate-color('teal-A700', $teal-A700);


// green
@include generate-color('green-50', $green-50);
@include generate-color('green-100', $green-100);
@include generate-color('green-200', $green-200);
@include generate-color('green-300', $green-300);
@include generate-color('green-400', $green-400);
@include generate-color('green-500', $green-500);
@include generate-color('green-600', $green-600);
@include generate-color('green-700', $green-700);
@include generate-color('green-800', $green-800);
@include generate-color('green-900', $green-900);

@include generate-color('green-A100', $green-A100);
@include generate-color('green-A200', $green-A200);
@include generate-color('green-A400', $green-A400);
@include generate-color('green-A700', $green-A700);


// light-green
@include generate-color('light-green-50', $light-green-50);
@include generate-color('light-green-100', $light-green-100);
@include generate-color('light-green-200', $light-green-200);
@include generate-color('light-green-300', $light-green-300);
@include generate-color('light-green-400', $light-green-400);
@include generate-color('light-green-500', $light-green-500);
@include generate-color('light-green-600', $light-green-600);
@include generate-color('light-green-700', $light-green-700);
@include generate-color('light-green-800', $light-green-800);
@include generate-color('light-green-900', $light-green-900);

@include generate-color('light-green-A100', $light-green-A100);
@include generate-color('light-green-A200', $light-green-A200);
@include generate-color('light-green-A400', $light-green-A400);
@include generate-color('light-green-A700', $light-green-A700);


// lime
@include generate-color('lime-50', $lime-50);
@include generate-color('lime-100', $lime-100);
@include generate-color('lime-200', $lime-200);
@include generate-color('lime-300', $lime-300);
@include generate-color('lime-400', $lime-400);
@include generate-color('lime-500', $lime-500);
@include generate-color('lime-600', $lime-600);
@include generate-color('lime-700', $lime-700);
@include generate-color('lime-800', $lime-800);
@include generate-color('lime-900', $lime-900);

@include generate-color('lime-A100', $lime-A100);
@include generate-color('lime-A200', $lime-A200);
@include generate-color('lime-A400', $lime-A400);
@include generate-color('lime-A700', $lime-A700);


// yellow
@include generate-color('yellow-50', $yellow-50);
@include generate-color('yellow-100', $yellow-100);
@include generate-color('yellow-200', $yellow-200);
@include generate-color('yellow-300', $yellow-300);
@include generate-color('yellow-400', $yellow-400);
@include generate-color('yellow-500', $yellow-500);
@include generate-color('yellow-600', $yellow-600);
@include generate-color('yellow-700', $yellow-700);
@include generate-color('yellow-800', $yellow-800);
@include generate-color('yellow-900', $yellow-900);

@include generate-color('yellow-A100', $yellow-A100);
@include generate-color('yellow-A200', $yellow-A200);
@include generate-color('yellow-A400', $yellow-A400);
@include generate-color('yellow-A700', $yellow-A700);


// amber
@include generate-color('amber-50', $amber-50);
@include generate-color('amber-100', $amber-100);
@include generate-color('amber-200', $amber-200);
@include generate-color('amber-300', $amber-300);
@include generate-color('amber-400', $amber-400);
@include generate-color('amber-500', $amber-500);
@include generate-color('amber-600', $amber-600);
@include generate-color('amber-700', $amber-700);
@include generate-color('amber-800', $amber-800);
@include generate-color('amber-900', $amber-900);

@include generate-color('amber-A100', $amber-A100);
@include generate-color('amber-A200', $amber-A200);
@include generate-color('amber-A400', $amber-A400);
@include generate-color('amber-A700', $amber-A700);


// orange
@include generate-color('orange-50', $orange-50);
@include generate-color('orange-100', $orange-100);
@include generate-color('orange-200', $orange-200);
@include generate-color('orange-300', $orange-300);
@include generate-color('orange-400', $orange-400);
@include generate-color('orange-500', $orange-500);
@include generate-color('orange-600', $orange-600);
@include generate-color('orange-700', $orange-700);
@include generate-color('orange-800', $orange-800);
@include generate-color('orange-900', $orange-900);

@include generate-color('orange-A100', $orange-A100);
@include generate-color('orange-A200', $orange-A200);
@include generate-color('orange-A400', $orange-A400);
@include generate-color('orange-A700', $orange-A700);


// deep-orange
@include generate-color('deep-orange-50', $deep-orange-50);
@include generate-color('deep-orange-100', $deep-orange-100);
@include generate-color('deep-orange-200', $deep-orange-200);
@include generate-color('deep-orange-300', $deep-orange-300);
@include generate-color('deep-orange-400', $deep-orange-400);
@include generate-color('deep-orange-500', $deep-orange-500);
@include generate-color('deep-orange-600', $deep-orange-600);
@include generate-color('deep-orange-700', $deep-orange-700);
@include generate-color('deep-orange-800', $deep-orange-800);
@include generate-color('deep-orange-900', $deep-orange-900);

@include generate-color('deep-orange-A100', $deep-orange-A100);
@include generate-color('deep-orange-A200', $deep-orange-A200);
@include generate-color('deep-orange-A400', $deep-orange-A400);
@include generate-color('deep-orange-A700', $deep-orange-A700);


// brown
@include generate-color('brown-50', $brown-50);
@include generate-color('brown-100', $brown-100);
@include generate-color('brown-200', $brown-200);
@include generate-color('brown-300', $brown-300);
@include generate-color('brown-400', $brown-400);
@include generate-color('brown-500', $brown-500);
@include generate-color('brown-600', $brown-600);
@include generate-color('brown-700', $brown-700);
@include generate-color('brown-800', $brown-800);
@include generate-color('brown-900', $brown-900);


// grey
@include generate-color('grey-50', $grey-50);
@include generate-color('grey-100', $grey-100);
@include generate-color('grey-200', $grey-200);
@include generate-color('grey-300', $grey-300);
@include generate-color('grey-400', $grey-400);
@include generate-color('grey-500', $grey-500);
@include generate-color('grey-600', $grey-600);
@include generate-color('grey-700', $grey-700);
@include generate-color('grey-800', $grey-800);
@include generate-color('grey-900', $grey-900);


// blue-grey
@include generate-color('blue-grey-50', $blue-grey-50);
@include generate-color('blue-grey-100', $blue-grey-100);
@include generate-color('blue-grey-200', $blue-grey-200);
@include generate-color('blue-grey-300', $blue-grey-300);
@include generate-color('blue-grey-400', $blue-grey-400);
@include generate-color('blue-grey-500', $blue-grey-500);
@include generate-color('blue-grey-600', $blue-grey-600);
@include generate-color('blue-grey-700', $blue-grey-700);
@include generate-color('blue-grey-800', $blue-grey-800);
@include generate-color('blue-grey-900', $blue-grey-900);


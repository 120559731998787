/*------------------------------------------------------------------
[General Styles]
*/

$selection-bg: $brand-primary;
$selection-color: #fff;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: scrollbar;
	overflow-x: hidden;
}
img {
	max-width: 100%;
	height: auto;
}
iframe {
	border: 0;
}

a {
	i.icons-small {
		font-size: 10px;
		font-weight: bold !important;
	}
}

// helper classes to override the site-wide border radius

.radius {
	border-radius: $brand-custom-radius !important;
}
.radius-top {
	border-top-right-radius: $brand-custom-radius !important;
	border-top-left-radius: $brand-custom-radius !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.radius-right {
	border-top-right-radius: $brand-custom-radius !important;
	border-bottom-right-radius: $brand-custom-radius !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.radius-bottom {
	border-bottom-right-radius: $brand-custom-radius !important;
	border-bottom-left-radius: $brand-custom-radius !important;
	border-top-right-radius: 0 !important;
	border-top-left-radius: 0 !important;
}
.radius-left {
	border-top-left-radius: $brand-custom-radius !important;
	border-bottom-left-radius: $brand-custom-radius !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.round {
	border-radius: $brand-custom-round !important;
}


.disable-hover {
	pointer-events: none;
}
::-moz-selection {
	background: $selection-bg;
	color: $selection-color !important;
}
::-webkit-selection {
	background: $selection-bg;
	color: $selection-color !important;
}
::selection {
	background: $selection-bg;
	color: $selection-color !important;
}
.relative {
	position: relative;
}
@media(max-width: 375px) {
	.hidden-xxs {
		display: none;
	}
}
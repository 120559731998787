/*------------------------------------------------------------------
[Stars Rating]
*/

.star-rating {
	font-family: FontAwesome;
	color: $brand-primary;
}
.star-rating-1:after {
	content: "\f005\20\f006\20\f006\20\f006\20\f006";
}
.star-rating-2:after {
	content: "\f005\20\f005\20\f006\20\f006\20\f006";
}
.star-rating-3:after {
	content: "\f005\20\f005\20\f005\20\f006\20\f006";
}
.star-rating-4:after {
	content: "\f005\20\f005\20\f005\20\f005\20\f006";
}
.star-rating-5:after {
	content: "\f005\20\f005\20\f005\20\f005\20\f005";
}
.reviews .comment-content {
	margin-left: 0;
	p {
		margin-bottom: 10px;
	}
}
/*------------------------------------------------------------------
[Widgets]
*/

$widget-recent-posts-li-border-color: #eee;
$widget-events-li-border-color: #eee;
$icons-list-li-border-color: #eee;

$icons-list-a-color: #999;
$icons-list-a-color-hover: $brand-primary;

$widget-top-rated-li-border-color: #eee;

$widget-event-a-color: #444;
$widget-event-a-color-hover: $link-hover-color;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.widget {
	margin: 0 0 40px;
}

.widget > *:last-child {
	margin-bottom: 0;
}

/* --- [ Recent Posts Widget ] --- */

.widget .recent-posts {
	list-style: none;
	padding: 0;
	margin: 0;
}


.widget .recent-posts > li {
	border-bottom: 1px solid $widget-recent-posts-li-border-color;
	padding: 10px 0;
}

.widget .recent-posts > li:before,
.widget .recent-posts > li:after {
	content: " ";
	display: table;
}

.widget .recent-posts > li:after {
	clear: both;
}

.widget .recent-posts > li:first-child {
	padding-top: 0;
}

.widget .recent-posts a {
	text-decoration: none;
}

.widget .recent-posts a:hover,
.widget .recent-posts a:focus {
	/*opacity: 0.7;*/
}

.widget-posts-image {
	float: left;
	width: 44px;
}

.widget-posts-body {
	margin-left: 58px;
}

.widget-posts-title {
	margin: 2px 0;
}

.widget-posts-meta {
	font-size: 12px;
}


/* --- [ Events Widget ] --- */


.widget .events {
	list-style: none;
	padding: 0;
	margin: 0;
}


.widget .events > li {
	border-bottom: 1px solid $widget-events-li-border-color;
	padding: 10px 0;
}

.widget .events > li:before,
.widget .events > li:after {
	content: " ";
	display: table;
}

.widget .events > li:after {
	clear: both;
}

.widget .events > li:first-child {
	padding-top: 0;
}

.widget .events a {
	color: $widget-event-a-color;
}

.widget .events a:hover,
.widget .events a:focus {
	color: $widget-event-a-color-hover;
}





/* --- [ Twitter Feed Widget ] --- */

.twitter-feed {
	word-wrap: break-word;
}

.twitter-feed ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.twitter-feed ul li:before {
	position: relative;
	top: 6px;
	float: left;
	display: inline-block;
	content: "\f099";
	font-family: 'FontAwesome';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 13px;
}

.twitter-feed .user,
.twitter-feed .timePosted,
.twitter-feed .interact {
	display: none;
}

.twitter-feed p {
	position: relative;
	padding-left: 20px;
}

/* --- [ Category Widget ] --- */

.icons-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.icons-list > li {
	border-bottom: 1px solid $icons-list-li-border-color;
	padding: 10px 0;
}

.icons-list > li:first-child {
	padding-top: 0;
}


.icons-list a {
	color: $icons-list-a-color;
}

.icons-list a:hover,
.icons-list a:focus {
	color: $icons-list-a-color-hover;
}

.icons-list a > .fa,
.icons-list a > .icons {
	padding-left: 5px;
}

/* --- [  Widget ] --- */

.widget address {
	line-height: 1.8;
}

address.map-background {
	background: url("../images/map.png") no-repeat left;
}

.links-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.links-list > li {
	margin: 0 0 15px;
}

.links-list > li a {
	text-decoration: none;
}

.widget .links-list a:hover {
	/*opacity: 0.7;*/
}

/* --- [ Top Rated Widget ] --- */

.widget .top-rated {
	list-style: none;
	padding: 0;
	margin: 0;
}


.widget .top-rated > li {
	border-bottom: 1px solid $widget-top-rated-li-border-color;
	padding: 10px 0;
	display: table;
	width: 100%;
}

.widget .top-rated > li:first-child {
	padding-top: 0;
}

.widget .top-rated .top-rated-body,
.widget .top-rated .top-rated-image {
	display: table-cell;
	vertical-align: middle;
}

.widget .top-rated .top-rated-body {
	padding: 0 0 0 14px;
}

.widget .top-rated .top-rated-image {
	width: 70px;
}

/*------------------------------------------------------------------
[Typography]
*/

$hx-color: #222;

$hx1-color: $brand-primary;
$hx2-color: #222;
$hx3-color: #222;
$hx4-color: #222;
$hx5-color: #222;
$hx6-color: #222;

$hx-a-color: #222;

$blockquote-bg: #f8f8f8;

$bottom-line-border-width: 1px;
$bottom-line-border-color: #dce1e6;

$bottom-line-border1: #eeeeee;
$bottom-line-border2: $brand-primary;

$divider-line-bg: $brand-danger;


$color-white-color: #ffffff;
$color-white-dark-color: #f8f8f8;
$color-white-darken-color: #f5f5f5;
$color-gray-color: #777777;
$color-gray-light-color: #999999;
$color-gray-lighter-color: #eeeeee;
$color-black-color: #000000;
$color-black-light-color: #222222;
$color-black-lighter-color: #333333;
$color-brand-color: $brand-primary;
$color-brand-hvr-color: #1fa098;
$color-facebook-color: #3b5998;
$color-twitter-color: #00aced;
$color-google-color: #dd4b39;
$color-linkedin-color: #007bb6;
$color-youtube-color: #bb0000;
$color-instagram-color: #517fa4;
$color-pinterest-color: #cb2027;
$color-flickr-color: #ff0084;
$color-tumblr-color: #32506d;
$color-foursquare-color: #0072b1;
$color-dribbble-color: #ea4c89;
$color-vine-color: #00bf8f;
$color-skype-color: #17a3eb;
$color-wordpress-color: #2592c3;
$color-behance-color: #1879fd;
$color-soundcloud-color: #ff7e30;
$color-stumbleupon-color: #ff5c30;
$color-deviantart-color: #6a8a7b;
$color-yahoo-color: #ab47ac;
$color-digg-color: #75788d;
$color-github-color: #3f91cb;
$color-lastfm-color: #f34320;
$color-vk-color: #2b587a;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

a:hover,
a:focus {
	outline: 0;
	text-decoration: none;
}

h1 a,
.h1 a {
	color: $hx1-color;
}
h2 a,
.h2 a {
	color: $hx2-color;
}
h3 a,
.h3 a {
	color: $hx3-color;
}
h4 a,
.h4 a {
	color: $hx4-color;
}

h5 a,
.h5 a {
	color: $hx5-color;
}

h6 a,
.h6 a {
	color: $hx6-color;
}

p,
ul,
ol,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
blockquote {
	margin: 0 0 20px;
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: 0 0 10px;
}

h1,
.h1 {
	color: $hx1-color;
}
h2,
.h2 {
	color: $hx2-color;
}
h3,
.h3 {
	color: $hx3-color;
}
h4,
.h4 {
	color: $hx4-color;
}
h5,
.h5 {
	color: $hx5-color;
}
h6,
.h6 {
	color: $hx6-color;
}

h1,
.h1 {
	font-size: 32px;
}

h2,
.h2 {
	font-size: 26px;
}

h3,
.h3 {
	font-size: 18px;
}

h4,
.h4 {
	font-size: 16px;
}

h5,
.h5 {
	font-size: 14px;
}

h6,
.h6 {
	font-size: 12px;
}


h1.text-uppercase,
.h1.text-uppercase {
	letter-spacing: 7px;
	margin-right: -7px;
}

h2.text-uppercase,
.h2.text-uppercase {
	letter-spacing: 5px;
	margin-right: -5px;
}

h3.text-uppercase,
.h3.text-uppercase {
	letter-spacing: 5px;
	margin-right: -5px;
}

h4.text-uppercase,
.h4.text-uppercase {
	letter-spacing: 3px;
	margin-right: -3px;
}

h5.text-uppercase,
.h5.text-uppercase {
	letter-spacing: 1px;
	margin-right: -1px;
}

h6.text-uppercase,
.h6.text-uppercase {
	letter-spacing: 1px;
	margin-right: -1px;
}

.hfont {
	font-family: $headings-font-family;
	font-weight: 300;
}

.lead {
	line-height: 1.8;
	font-weight: 400;
	font-size: 16px;
}

.divider-line:after {
	display: block;
	background: $divider-line-bg;
	content: " ";
	margin: 20px auto 20px 0;
	opacity: 0.6;
	height: 2px;
	width: 160px;
}

.text-center .divider-line:after {
	margin: 20px auto 40px;
}

.text-right .divider-line:after {
	margin: 20px 0 20px auto;
}

blockquote {
	border: 0;
	background: $blockquote-bg;
	font-style: italic;
	font-size: 16px;
	padding: 25px;
}

hr {
	margin: 15px 0;
}

/*
.bottom-line {
	position: relative;
}

.bottom-line:before {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-bottom: 2px solid $bottom-line-border1;
}

.bottom-line:after {
	content: "";
	display: block;
	width: 35px;
	border-bottom: 2px solid $bottom-line-border2;
	margin: 20px auto 25px 0;
	z-index: 1;
	position: relative;
}
*/

.bottom-line:after {
	content: "";
	display: block;
	width: 100%;
	border-bottom: $bottom-line-border-width solid $bottom-line-border-color;
	margin: 20px auto 25px 0;
	position: relative;
}


.bottom-line.text-right:after {
	margin: 20px 0 25px auto;
}

.bottom-line.text-center:after {
	margin: 20px auto 25px;
}

.dash-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.dash-list > li {
	margin: 0 0 5px;
}

.dash-list > li:before {
	display: inline-block;
	content: "-";
	margin-right: 12px;
}

/* --- [Colored Text] --- */











.color-white {
	color: $color-white-color !important;
}

.color-white-dark {
	color: $color-white-dark-color !important;
}

.color-white-darken {
	color: $color-white-darken-color !important;
}

.color-gray {
	color: $color-gray-color !important;
}

.color-gray-light {
	color: $color-gray-light-color !important;
}

.color-gray-lighter {
	color: $color-gray-lighter-color !important;
}

.color-black {
	color: $color-black-color !important;
}

.color-black-light {
	color: $color-black-light-color !important;
}

.color-black-lighter {
	color: $color-black-lighter-color !important;
}

.color-brand {
	color: $color-brand-color !important;
}

.color-brand-hvr {
	color: $color-brand-hvr-color !important;
}





.color-facebook {
	color: $color-facebook-color !important;
}

.color-twitter {
	color: $color-twitter-color !important;
}

.color-google {
	color: $color-google-color !important;
}

.color-linkedin {
	color: $color-linkedin-color !important;
}

.color-youtube {
	color: $color-youtube-color !important;
}

.color-instagram {
	color: $color-instagram-color !important;
}

.color-pinterest {
	color: $color-pinterest-color !important;
}

.color-flickr {
	color: $color-flickr-color !important;
}

.color-tumblr {
	color: $color-tumblr-color !important;
}

.color-foursquare {
	color: $color-foursquare-color !important;
}

.color-dribbble {
	color: $color-dribbble-color !important;
}

.color-vine {
	color: $color-vine-color !important;
}

.color-skype {
	color: $color-skype-color !important;
}

.color-wordpress {
	color: $color-wordpress-color !important;
}

.color-behance {
	color: $color-behance-color !important;
}

.color-soundcloud {
	color: $color-soundcloud-color !important;
}

.color-stumbleupon {
	color: $color-stumbleupon-color !important;
}

.color-deviantart {
	color: $color-deviantart-color !important;
}

.color-yahoo {
	color: $color-yahoo-color !important;
}

.color-digg {
	color: $color-digg-color !important;
}

.color-github {
	color: $color-github-color !important;
}

.color-lastfm {
	color: $color-lastfm-color !important;
}

.color-vk {
	color: $color-vk-color !important;
}

[class*="color-"] *:not(.btn) {
	color: inherit !important;
}

[class*="color-"] a:not(.btn) {
	color: inherit !important;
}

[class*="color-"] a:not(.btn):hover,
[class*="color-"] a:not(.btn):focus {
	opacity: 0.7;
}

/* --- [Font Size Text] --- */

.text-xxxxs {
	font-size: 10px;
}

.text-xxxs {
	font-size: 11px;
}

.text-xxs {
	font-size: 12px;
}

.text-xs {
	font-size: 13px;
}

.text-xl {
	font-size: 40px;
}

.text-xxl {
	font-size: 48px;
}

.text-xxxl {
	font-size: 56px;
}

.text-xxxxl {
	font-size: 64px;
}

.text-xxxxxl {
	font-size: 72px;
}

.text-super-xl {
	font-size: 110px;
}

@media (max-width: $screen-sm-max) {
	.text-super-xl {
		font-size: 72px;
	}
}

@media (max-width: $screen-xs-max) {
	.text-xl {
		font-size: 24px;
	}

	.text-xxl {
		font-size: 32px;
	}

	.text-xxxl {
		font-size: 36px;
	}

	.text-xxxxl {
		font-size: 40px;
	}

	.text-xxxxxl {
		font-size: 44px;
	}

	.text-super-xl {
		font-size: 48px;
	}
}

@media (max-width: 568px) {
	.text-super-xl {
		font-size: 34px;
	}
}

@media (max-width: 320px) {
	.text-super-xl {
		font-size: 26px;
	}
}

/* --- [ Font Weight Text ] --- */

.text-100 {
	font-weight: 100 !important;
}

.text-200 {
	font-weight: 200 !important;
}

.text-300 {
	font-weight: 300 !important;
}

.text-400 {
	font-weight: 400 !important;
}

.text-500 {
	font-weight: 500 !important;
}

.text-600 {
	font-weight: 600 !important;
}

.text-700 {
	font-weight: 700 !important;
}

.text-800 {
	font-weight: 800 !important;
}

.text-900 {
	font-weight: 900 !important;
}
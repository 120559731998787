/*------------------------------------------------------------------
[Social Icons]
*/

$social-icons-li-a-color: #777;
$social-icons-li-a-bg: #f5f5f5;

$social-icons-li-a-bg-hover: $brand-primary;
$social-icons-li-a-color-hover: #fff;

$social-icons-colored-li-a-color: #fff;

.social-icons {
	list-style: none;
	padding: 0;
	margin: 0;
}

.social-icons > li {
	display: inline-block;
}

.social-icons > li > a {
	background: $social-icons-li-a-bg;
	display: block;
	margin: 0 8px 8px 0;
	text-align: center;
	line-height: 32px;
	font-size: 14px;
	height: 32px;
	width: 32px;
	color: $social-icons-li-a-color;
}

.social-icons > li > a:hover,
.social-icons > li > a:focus {
	background: $social-icons-li-a-bg-hover;
	color: $social-icons-li-a-color-hover;
}

/* --- [ Social Icons Size ] --- */

.social-icons-lg > li > a {
	line-height: 48px;
	font-size: 16px;
	height: 48px;
	width: 48px;
}

.social-icons-md > li > a {
	line-height: 40px;
	height: 40px;
	width: 40px;
}

/* --- [ Social Icons Mode ] --- */

.social-icons-round > li > a {
	border-radius: $brand-sitewide-radius;
}

.social-icons-circle > li > a {
	border-radius: 50%;
}

/* --- [ Social Icons Colored ] --- */

.social-icons-colored > li > a {
	color: $social-icons-colored-li-a-color;
}

.social-icons-colored > li > a:hover,
.social-icons-colored > li > a:focus {
	opacity: 0.85;
}

/* --- [ Social Icons Simple ] --- */

.social-icons-simple > li > a {
	display: inline;
	background: none;
	margin: 0 10px 10px 0;
}

.social-icons-simple > li > a:hover,
.social-icons-simple > li > a:focus {
	background: none;
	color: inherit;
}


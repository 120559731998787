/*------------------------------------------------------------------
[Tabs and Accordions]
*/

$nav-tabs-color: #777;
$nav-tabs-border-color: #eee;

$nav-text-tabs-border-active: $brand-primary;

.nav-tabs {
	border-bottom: 1px solid $nav-tabs-border-color;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border: 1px solid $nav-tabs-border-color;
	border-bottom-color: transparent;
	color: $nav-tabs-color;
}

.nav-tabs + .tab-content {
	border: 1px solid $nav-tabs-border-color;
	border-top: 0;
	padding: 20px 15px;
}

.nav-tabs + .tab-content *:last-child {
	margin-bottom: 0;
}

/* --- [ Text Tabs ] --- */

.nav-text-tabs {
	border-bottom: 2px solid $nav-tabs-border-color;
	list-style: none;
	padding: 0;
	margin: 0;
}

.nav-text-tabs > li {
	display: inline-block;
}

.nav-text-tabs > li > a {
	position: relative;
	display: block;
	padding: 10px 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 12px;
	color: $nav-tabs-color;
}

.nav-text-tabs > li > a:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background: $nav-text-tabs-border-active;
	bottom: 10px;
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	bottom: -2px;
	left: 0;
}

.nav-text-tabs > li.active > a {
	color: $nav-text-tabs-border-active;
}

.nav-text-tabs > li.active > a:after {
	opacity: 1;
}

.nav-text-tabs + .tab-content {
	padding: 20px 0;
}

@media (max-width: 527px) {
	.nav-text-tabs > li {
		border-top: 1px solid $nav-tabs-border-color;
		text-align: center;
		display: block;
	}

	.nav-text-tabs > li > a {
		padding: 20px 15px;
	}

	.nav-text-tabs > li > a:after {
		content: none;
	}
}

.panel-heading a {
	position: relative;
	display: block;
}

.panel-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 12px;
}

.panel-default > .panel-heading {
	border: 0;
	background: transparent;
	border-radius: $brand-sitewide-radius;
	border-top: 1px solid $nav-tabs-border-color;
	padding: 20px 15px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: $nav-tabs-border-color;
}

.panel-heading a:after {
	position: absolute;
	content: "\f107";
	top: 50%;
	right: 0px;
	margin-top: -5px;
	font-family: "FontAwesome";
	font-size: 11px;
	line-height: 1;
}

.panel-heading a.collapsed:after {
	content: "\f105";
}

.panel-group .panel + .panel {
	margin-top: 0;
}

.panel-group .panel {
	border: 0;
	border-radius: $brand-sitewide-radius;
	box-shadow: none;
}

.panel-group .panel:last-child {
	border-bottom: 1px solid $nav-tabs-border-color;
}

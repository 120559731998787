/*------------------------------------------------------------------
[Testimonials]
*/

$slider-testimonial-owl-page-span-bg: #fff;
$owl-controls-brand-owl-page-span-bg: $brand-primary;
$owl-controls-brand-owl-page-active-span-box-shadow: $brand-primary;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.testimonial-image {
	text-align: center;
}

.testimonial-image img {
	border-radius: 50%;
	margin-right: 15px;
	max-width: 75px;
}

.testimonial-image img,
.testimonial-image .cd-author-info {
	display: inline-block;
	vertical-align: middle;
}

.testimonial-image .cd-author-info {
	text-align: left;
}

.testimonial {
	text-align: center;
	border: 0;
	padding: 0;
	margin: 35px 0 0;
	font-size: 16px;
}

.slider-testimonial.owl-theme .owl-controls .owl-buttons div {
	background: none;
	padding: 0;
	margin: 0;
	opacity: 1;
	font-size: 20px;
}

.slider-testimonial.owl-theme .owl-controls .owl-buttons div.owl-prev {
	position: absolute;
	left: 0px;
	top: 50%;
	margin: -10px 0 0;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.slider-testimonial.owl-theme .owl-controls .owl-buttons div.owl-next {
	position: absolute;
	right: 0px;
	top: 50%;
	margin: -10px 0 0;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.slider-testimonial.owl-theme .owl-controls .owl-buttons div.owl-prev:hover,
.slider-testimonial.owl-theme .owl-controls .owl-buttons div.owl-next:hover {
	opacity: 0.7;
}

.slider-testimonial .owl-item {
	padding: 0 100px;
}

.slider-testimonial .owl-pagination {
	margin: 30px 0 0;
}

.slider-testimonial .owl-page span {
	background: $slider-testimonial-owl-page-span-bg;
	box-shadow: 0px 0px 0px 3px transparent;
	border-radius: 50%;
	display: block;
	height: 10px;
	width: 10px;
	opacity: 0.5;
	zoom: 1;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.slider-testimonial .owl-page.active span {
	background: transparent;
	box-shadow: 0px 0px 0px 3px white;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	opacity: 1;
	zoom: 1;
}

.owl-controls-brand .owl-page span {
	background: $owl-controls-brand-owl-page-span-bg;
}


.owl-controls-brand .owl-page.active span {
	background: transparent;
	box-shadow: 0px 0px 0px 3px $owl-controls-brand-owl-page-active-span-box-shadow;
}

@media (max-width: $screen-xs-max) {
	.testimonial-image .cd-author-info,
	.testimonial-image img {
		display: block;
		margin: 0 auto 15px;
		text-align: center;
	}
}

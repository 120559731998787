/*------------------------------------------------------------------
[Portfolio]
*/

$filters-li-a-color: #333;
$filters-li-a-border-hover-color: #333;

$work-title-color: #fff;
$work-category-color: #fff;

$works-grid-white-title-color: #222;
$works-grid-white-category-color: #222;

$portfolio-info-li-border-color: #eee;

$works-grid-dark-bg: #333;

.filters {
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0;
	> li {
		display: inline-block;
		padding: 0 15px 35px;
		> a {
			border-bottom: 1px solid transparent;
			text-decoration: none;
			font-weight: 400;
			font-size: 14px;
			color: $filters-li-a-color;
			padding: 1px 0;
			opacity: 0.6;
			-webkit-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
			&:hover {
				border-color: $filters-li-a-border-hover-color;
				opacity: 1;
			}
			&.current {
				border-color: $filters-li-a-border-hover-color;
				opacity: 1;
			}
		}
	}
}
.works-grid {
	&.works-grid-gutter {
		margin-left: -4px;
		margin-right: -4px;
		.work-item {
			padding: 0 4px 8px;
		}
	}
	.work-item {
		width: 50%;
	}
	&.works-grid-3 .work-item {
		width: 33.333333%;
	}
	&.works-grid-4 .work-item {
		width: 25%;
	}
	&.works-grid-5 .work-item {
		width: 20%;
	}
}
.work-wrapper {
	position: relative;
	overflow: hidden;
	height: 100%;
	> img {
		width: 100%;
		-webkit-transition: $main-custom-transition;
		transition: $main-custom-transition;
	}
}
.work-overlay {
	border: none;
	background: rgba($brand-primary, 0.8);
	position: absolute;
	opacity: 0;
	z-index: 1;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	-webkit-transition: $main-custom-transition;
	transition: $main-custom-transition;
}
.work-caption {
	-webkit-transition: $main-custom-transition;
	transition: $main-custom-transition;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	position: absolute;
	padding: 40px;
	width: 100%;
	opacity: 0;
	bottom: 0;
}
.work-title {
	color: $work-title-color;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
}
.work-category {
	color: $work-category-color;
	text-transform: lowercase;
	font-style: italic;
	font-size: 13px;
	opacity: 0.7;
}
.work-link {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	z-index: 2;
}
.works-grid-white {
	.work-overlay {
		background: rgba($pure-white, 0.9);
	}
	.work-title {
		color: $works-grid-white-title-color;
	}
	.work-category {
		color: $works-grid-white-category-color;
	}
}
@media(max-width: $screen-sm-max) {
	.works-grid {
		.work-item {
			width: 50%;
		}
		&.works-grid-3 .work-item {
			width: 50%;
		}
		&.works-grid-4 .work-item {
			width: 50%;
		}
		&.works-grid-5 .work-item {
			width: 50%;
		}
	}
}
@media(max-width: 568px) {
	.works-grid {
		.work-item {
			width: 100%;
		}
		&.works-grid-3 .work-item {
			width: 100%;
		}
		&.works-grid-4 .work-item {
			width: 100%;
		}
		&.works-grid-5 .work-item {
			width: 100%;
		}
	}
}
.portfolio-info {
	list-style: none;
	padding: 0;
	margin: 0 0 35px;
	h5 {
		display: inline-block;
		margin: 0 8px 0 0;
	}
	.social-icons {
		display: inline-block;
	}
	> li:not(:last-child) {
		border-bottom: 1px solid $portfolio-info-li-border-color;
		padding: 0 0 8px;
		margin: 0 0 8px;
	}
}
.work-item:hover {
	.work-overlay {
		opacity: 1;
	}
	.work-caption {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	.work-wrapper img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}
.works-grid-dark .work-overlay {
	background: rgba($works-grid-dark-bg, 0.9);
}

/*------------------------------------------------------------------
[Row as Table]
*/

.row-height {
	display: table;
	table-layout: fixed;
	height: 100%;
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}
.col-height {
	display: table-cell;
	float: none;
	height: 100%;
}
.col-top {
	vertical-align: top;
}
.col-middle {
	vertical-align: middle;
}
.col-bottom {
	vertical-align: bottom;
}
@media(min-width: 480px) {
	.row-xs-height {
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.col-xs-height {
		display: table-cell;
		float: none;
		height: 100%;
	}
	.col-xs-top {
		vertical-align: top;
	}
	.col-xs-middle {
		vertical-align: middle;
	}
	.col-xs-bottom {
		vertical-align: bottom;
	}
}
@media(min-width: $screen-sm-min) {
	.row-sm-height {
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.col-sm-height {
		display: table-cell;
		float: none;
		height: 100%;
	}
	.col-sm-top {
		vertical-align: top;
	}
	.col-sm-middle {
		vertical-align: middle;
	}
	.col-sm-bottom {
		vertical-align: bottom;
	}
}
@media(min-width: $screen-md-min) {
	.row-md-height {
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.col-md-height {
		display: table-cell;
		float: none;
		height: 100%;
	}
	.col-md-top {
		vertical-align: top;
	}
	.col-md-middle {
		vertical-align: middle;
	}
	.col-md-bottom {
		vertical-align: bottom;
	}
}
@media(min-width: $screen-lg-min) {
	.row-lg-height {
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.col-lg-height {
		display: table-cell;
		float: none;
		height: 100%;
	}
	.col-lg-top {
		vertical-align: top;
	}
	.col-lg-middle {
		vertical-align: middle;
	}
	.col-lg-bottom {
		vertical-align: bottom;
	}
}
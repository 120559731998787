/*------------------------------------------------------------------
[Alerts]
*/

// Extra alerts
$alert-brand-bg:                  $brand-primary;
$alert-brand-border:              $brand-primary;
$alert-brand-text:                darken($brand-primary,40%);

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.alert {
	border: 0;
}

.alert-brand {
	@include alert-variant($alert-brand-bg, $alert-brand-border, $alert-brand-text);
}

/*------------------------------------------------------------------
[Clients]
*/

$clients-border-color-xs: #999;
$clients-border-color: #eee;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.client {
	text-align: center;
	opacity: 0.7;
	-webkit-transition: $main-custom-transition;
	transition: $main-custom-transition;
	&:hover {
		opacity: 1;
	}
	&:focus {
		opacity: 1;
	}
}
.clients-carousel {
	text-align: center;
	.owl-item a {
		-webkit-transition: $main-custom-transition;
		transition: $main-custom-transition;
		opacity: 0.7;
		&:hover {
			opacity: 1;
		}
		&:focus {
			opacity: 1;
		}
	}
}
@media(max-width: $screen-sm-max) {
	.clients-bordered .client:nth-child(3n) {
		border: 0;
	}
}
@media(max-width: $screen-xs-max) {
	.clients-bordered .client {
		&:nth-child(3n) {
			border-right: 1px solid $clients-border-color-xs;
		}
		&:nth-child(2n) {
			border: 0;
		}
	}
}
.clients-bordered .client {
	padding: 70px 10px;
	&:not(:last-child) {
		border-right: 1px solid $clients-border-color;
	}
}
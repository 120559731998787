/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Bootstrap
// @import "bootstrap-variables";
@import "colors-variables";
@import "lara5-variables";
@import "bootstrap";
// @import "bootstrap/theme";

// Icon Fonts
@import "fonts/font-awesome";
@import "fonts/simple-line-icons";

// Plugins
@import "plugins/block-grid";
@import "plugins/baguetteBox";
@import "plugins/magnific-popup";
@import "plugins/owl.carousel";
@import "plugins/flexslider";
@import "plugins/animate";
@import "plugins/pace";

// Lara5
@import "lara5";

// Site specific styles
@import "styles";



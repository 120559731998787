/*------------------------------------------------------------------
[Label]
*/

.label {
	padding: .2em .6em .4em;
}

.label-base {
	background: $brand-primary;
}

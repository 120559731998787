/*------------------------------------------------------------------
[Header Search]
*/

$header-search-form-bg: #333;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.header-search-form {
	background: rgba($header-search-form-bg, 0.95);
	display: inline-block;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	&.opened {
		visibility: visible;
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
.header-search-form-clouse {
	text-align: center;
	font-size: 24px;
	padding: 0 0 40px;
}
.form-close-btn {
	color: $pure-white;
	&:hover {
		color: rgba($pure-white, 0.8);
	}
}
.search-form-inner {
	position: absolute;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	input {
		background: transparent;
		width: 100%;
		border: none;
		font-size: 48px;
		color: $pure-white;
		text-align: center;
		&:focus {
			border-color: transparent;
			box-shadow: none;
			outline: none;
		}
	}
}
@media(max-width: $screen-sm-max) {
	.search-form-inner input {
		font-size: 38px;
	}
}
@media(max-width: $screen-xs-max) {
	.search-form-inner input {
		font-size: 24px;
	}
}
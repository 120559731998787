/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/

.flex-slider a:hover,
.flex-container a:hover {
	outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
	margin: 0;
	padding: 0;
}
.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}
.flexslider .slides img {
	width: 100%;
	display: block;
}
.flexslider .slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
html[xmlns] .flexslider .slides {
	display: block;
}
* html .flexslider .slides {
	height: 1%;
}
.no-js .flexslider .slides > li:first-child {
	display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/

.flexslider {
	position: relative;
	height: 70vh;
	margin: 0;
}

.flexslider .slides {
	height: 100% !important;
	-webkit-transition-delay: 1s;
	   -moz-transition-delay: 1s;
		    transition-delay: 1s;
}

.flexslider .slides li {
	background-position: center center;
	background-repeat: no-repeat;
	height: 100% !important;
	width: 100%;
	padding: 0;
	margin: 0;
	-webkit-background-size: cover;
	   -moz-background-size: cover;
		 -o-background-size: cover;
			background-size: cover;
}

.flex-nav-prev,
.flex-nav-next {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 0;
	margin-top: -10px;
}

.flex-nav-next {
	left: auto;
	right: 0;
}

.flex-nav-prev a,
.flex-nav-next a {
	display: block;
	font-size: 20px;
	color: #fff;
	padding: 0 20px;
}

.flex-nav-prev a:hover {
	padding-left: 15px;
}

.flex-nav-next a:hover {
	padding-right: 15px;
}

.flex-control-nav {
	position: absolute;
	bottom: 20px;
	width: 100%;
	text-align: center;
	z-index: 10;
}

.flex-control-nav > li {
	display: inline-block;
	position: relative;
	margin: 0 5px;
}

.flex-control-nav > li > a {
	text-indent: -9999px;
	background: #fff;
	box-shadow: 0px 0px 0px 3px transparent;
	border-radius: 50%;
	display: block;
	height: 10px;
	width: 10px;
	opacity: 0.5;
	zoom: 1;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}


.flex-control-nav > li > a.flex-active {
	background: transparent;
	box-shadow: 0px 0px 0px 3px white;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	opacity: 1;
	zoom: 1;
}
/*------------------------------------------------------------------
[Module Hero]
*/

.module-hero {
	background-color: $pure-white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding: 0;
	height: 70vh;
	overflow: hidden;
	&.parallax {
		background-color: transparent;
	}
}

.hero-caption-bottom {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: rgba($pure-black, 0.6);
	color: white;
	width: 100%;
	font-size: 16px;

	.container .row {
		padding: 10px 40px;
	}
}

.flex-control-nav {
	bottom: 0;
}

.hero-caption {
	position: relative;
	display: table;
	height: 100%;
	width: 80%;
	margin: 0 auto;
	z-index: 2;
}

.hero-text {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 30px;
	font-weight: 400;
	h1 {
		font-family: 'Montserrat', Arial, sans-serif;
		font-size: 60px;
		font-weight:600;
	}
}

@media (max-width: $screen-sm-max) {
	.hero-text {
		font-size: 36px;
		h1 {
			font-size: 60px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.hero-text {
		font-size: 20px;
		h1 {
			font-size: 40px;
		}
	}
}


.fullheight {
	height: 100vh;
}

.hero-h10 {
	height: 10vh;
}

.hero-h20 {
	height: 20vh;
}

.hero-h30 {
	height: 30vh;
}

.hero-h40 {
	height: 40vh;
}

.hero-h50 {
	height: 50vh;
}

.hero-h60 {
	height: 60vh;
}

.hero-h70 {
	height: 70vh;
}

.hero-h80 {
	height: 80vh;
}

.hero-h90 {
	height: 90vh;
}

@media(max-width: $screen-sm-max) {
	.module-hero {
		height: auto;
		padding: 240px 0;
	}
}

.animated-background {
	-webkit-animation: animated-background 800s linear infinite;
	animation: animated-background 800s linear infinite;
	background-repeat: repeat-x !important;
}

@-webkit-keyframes animated-background {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -50000px 0;
	}
}

@keyframes animated-background {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -50000px 0;
	}
}




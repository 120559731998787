/*------------------------------------------------------------------
[Features Box]
*/

$feature-box-shadow-color: #000;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.feature {
	margin: 35px 0;
	img {
		width: 100%;
		margin: 0 0 15px;
	}
	&.feature-circle {
		text-align: center;
		img {
			width: auto;
			border-radius: 50%;
		}
	}
	.box-shadow {
		box-shadow: 0 6px 32px -15px $feature-box-shadow-color;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
		&:hover {
			opacity: 0.5;
		}
	}
}
.demo-img {
	padding: 0 20px;
}
/*------------------------------------------------------------------
[Progress Bars]
*/

$progress-bg-color: #f5f5f5;
$progress-bar-bg-color: $brand-primary;
$progress-bar-color: #333;

.progress {
	background-color: $progress-bg-color;
	border-radius: $brand-sitewide-radius;
	box-shadow: none;
	height: 4px;
	margin-bottom: 20px;
	overflow: visible;
}
.progress-bar {
	position: relative;
	background-color: $progress-bar-bg-color;
	border-radius: $brand-custom-round;
	box-shadow: none;
	color: $progress-bar-color;
	> span {
		position: absolute;
		padding: 4px 0;
		display: block;
		top: -27px;
		right: 0;
		opacity: 0;
		line-height: 12px;
		font-size: 12px;
	}
	&.progress-bar-success {
		background-color: $brand-success;
	}
	&.progress-bar-info {
		background-color: $brand-info;
	}
	&.progress-bar-warning {
		background-color: $brand-warning;
	}
	&.progress-bar-danger {
		background-color: $brand-danger;
	}
}
.progress-title {
	letter-spacing: 1px;
	text-transform: uppercase;
	text-align: left;
	font-weight: 700;
	margin: 0 0 10px;
	padding: 0;
}
/*------------------------------------------------------------------
[Counters]
*/

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.counter {
	padding: 20px 0;
}

.counter-title {
	font-size: 40px;
}

.counter-content {
	margin: 0;
}

/*------------------------------------------------------------------
[Breadcrumb]
*/

$breadcrumb-a-color: #999;
$breadcrumb-a-color-hover: $brand-primary;
$breadcrumb-a-color-active: #444;
$breadcrumb-li-seperator-color: #999;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.breadcrumb {
	background: none;
	padding: 0;
	margin: 0;
	a {
		color: $breadcrumb-a-color;
		&:hover {
			color: $breadcrumb-a-color-hover;
		}
		&:focus {
			color: $breadcrumb-a-color-hover;
		}
	}
	> li + li:before {
		color: $breadcrumb-li-seperator-color;
		padding: 0 8px;
	}
	> .active {
		color: $breadcrumb-a-color-active;
		opacity: 0.9;
	}
}
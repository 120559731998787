/*!------------------------------------------------------------------
[Preloader]
*/

$page-loader-bg: #fff;
$loader-border-color-1: #333;
$loader-border-color-2: $brand-primary;

.page-loader {
	background: $page-loader-bg;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9998;
}
.loader {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	text-indent: -9999em;
	background-color: transparent;
	border: 5px solid $loader-border-color-1;
	border-radius: 50%;
	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
		padding: 2px;
		border-width: 2px;
		border-style: solid;
		border-color: $loader-border-color-2 transparent transparent transparent;
		border-radius: 50%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		animation: ringrotate 0.8s infinite cubic-bezier(0.5, 0.49, 0.49, 0.49);
		-webkit-animation: ringrotate 0.8s infinite cubic-bezier(0.5, 0.49, 0.49, 0.49);
	}
}
@keyframes ringrotate {
	0% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}
@-webkit-keyframes ringrotate {
	0% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}
/*------------------------------------------------------------------
[Forms]
*/

$form-control-color: $grey-800;
$form-control-border-color: $blue-grey-100;

$form-input-focus-color: $light-blue-700;

$search-form-color: #777;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.form-control {
	border: 1px solid $form-control-border-color;
	border-radius: 0;
	box-shadow: none;
	color: $form-control-color;
	height: 39px;
	font-size: 14px;
	&:focus {
		border-color: $form-input-focus-color;
		box-shadow: none;
	}
	&.form-transparent {
		background: transparent;
		border: 1px solid $pure-white;
		color: $pure-white;
	}
	&.form-circle {
		border-radius: $brand-custom-round;
	}
}
.form-group {
	margin-bottom: 20px;
}
.input-lg {
	height: 47px;
	font-size: 14px;
}
.input-sm {
	height: 38px;
	font-size: 13px;
}
.search-form {
	position: relative;
	&:after {
		position: absolute;
		top: 0;
		right: 10px;
		font-family: FontAwesome;
		line-height: 40px;
		content: '\f002';
		font-size: 14px;
		color: $search-form-color;
		opacity: 0.5;
		-webkit-font-smoothing: antialiased;
	}
}
.search-form-inner {
	input::-webkit-input-placeholder {
		color: $pure-white !important;
	}
	input {
		&:focus::-webkit-input-placeholder {
			color: $pure-white !important;
		}
		&:focus::-moz-placeholder {
			color: $pure-white !important;
		}
		&:-moz-placeholder {
			color: $pure-white !important;
		}
		&:focus {
			&:-moz-placeholder {
				color: $pure-white !important;
			}
			&:-ms-input-placeholder {
				color: $pure-white !important;
			}
		}
		&:-ms-input-placeholder {
			color: $pure-white !important;
		}
	}
	input::-moz-placeholder {
		color: $pure-white !important;
	}
}
input.form-control {
	&.form-transparent::-webkit-input-placeholder {
		color: $pure-white !important;
	}
	&.form-transparent::-moz-placeholder {
		color: $pure-white !important;
	}
	&.form-transparent {
		&:-moz-placeholder {
			color: $pure-white !important;
		}
		:-ms-input-placeholder {
			color: $pure-white !important;
		}
	}
}
/*------------------------------------------------------------------
[Widgets]
*/

$widget-home-a-color: #444;
$widget-home-a-color-hover: $link-hover-color;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.home-widget {
	margin: 0 0 40px;
}

/* --- [ Recent Posts Widget ] --- */

.home-widget .recent-posts {
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
}


.home-widget .recent-posts > li {
	border-bottom: none;
	padding: 5px 0;
}

.home-widget .recent-posts > li:before,
.home-widget .recent-posts > li:after {
	content: " ";
	display: table;
}

.home-widget .recent-posts > li:after {
	clear: both;
}

.home-widget .recent-posts > li:first-child {
	padding-top: 0;
}

.home-widget .recent-posts a {
	text-decoration: none;
}

.home-widget .recent-posts a {
	text-decoration: none;
	color: $widget-home-a-color;
}

.home-widget .recent-posts a:hover,
.home-widget .recent-posts a:focus {
	text-decoration: underline;
	color: $widget-home-a-color-hover;
}



/* --- [ Events Widget ] --- */


.home-widget .events {
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
}


.home-widget .events > li {
	border-bottom: none;
	padding: 5px 0;
}

.home-widget .events > li:before,
.home-widget .events > li:after {
	content: " ";
	display: table;
}

.home-widget .events > li:after {
	clear: both;
}

.home-widget .events > li:first-child {
	padding-top: 0;
}

.home-widget .events a {
	text-decoration: none;
	color: $widget-home-a-color;
}

.home-widget .events a:hover,
.home-widget .events a:focus {
	text-decoration: underline;
	color: $widget-home-a-color-hover;
}


/* --- [ Docs Widget ] --- */

.home-widget .docs {
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
}


.home-widget .docs > li {
	border-bottom: none;
	padding: 5px 0;
}

.home-widget .docs > li:before,
.home-widget .docs > li:after {
	content: " ";
	display: table;
}

.home-widget .docs > li:after {
	clear: both;
}

.home-widget .docs > li:first-child {
	padding-top: 0;
}

.home-widget .docs a {
	text-decoration: none;
	color: $widget-home-a-color;
}

.home-widget .docs a:hover,
.home-widget .docs a:focus {
	text-decoration: underline;
	color: $widget-home-a-color-hover;
}



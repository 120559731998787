/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/*------------------------------------------------------------------
[ Bootstrap Variables ]
*/

$body-bg:                         #fafbfc;
$text-color:                      $grey-800;

$brand1:                          $blue-700;
$brand2:                          $light-blue-500;
$brand3:                          $red-700;
$brand4:                          $orange-700;

$brand5:                          $indigo-900;

$brand-primary:                   $blue-700;
$brand-success:                   $green-500;
$brand-info:                      $light-blue-500;
$brand-warning:                   $amber-500;
$brand-danger:                    #af4b96;


$link-color:                      $blue-700;
$link-hover-color:                #af4b96;

$font-family-sans-serif:          "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:                  16px;
$line-height-base:                1.8;

$headings-font-family:            "Roboto", Helvetica, Arial, serif;
$headings-font-weight:            300;
$headings-line-height:            1.1;

$border-radius-base:              0;
$border-radius-large:             0;
$border-radius-small:             0;

// Flat buttons
$btn-primary-border:              $brand-primary;
$btn-success-border:              $brand-success;
$btn-info-border:                 $brand-info;
$btn-warning-border:              $brand-warning;
$btn-danger-border:               $brand-danger;

// Media queries breakpoints

$screen-xs-min:                   480px;
$screen-sm-min:                   768px;
$screen-md:                       1024px;
$screen-lg-min:                   1200px;

// Grid gutter
$grid-gutter-width:               48px;


/*------------------------------------------------------------------
[ Lara 5 Variables ]
*/

// Main Border
$main-border-color:               $grey-100;
$main-border-style:               solid;
$main-border-width:               1px;

// Site-wide Radius
$brand-sitewide-radius:           0;

// Override Radius
$brand-custom-radius:             6px;
$brand-custom-round:              1000px;

// Transitions
$main-custom-transition:          all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);

// Animate Custom
$trans3d-x: 20px;
$trans3d-y: 20px;
$trans3d-z: 20px;




/*------------------------------------------------------------------
[Top bar]
*/

$top-bar-bg: #fff;
$top-bar-border-color: #f5f5f5;

$top-bar-black-color: #fff;
$top-bar-black-bg: #000;

$top-bar-black-list-dividers-border-color: #222;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.top-bar {
	background: $top-bar-bg;
	border-bottom: 1px solid $top-bar-border-color;
	font-size: 12px;
	padding: 8px 0;
}

.top-bar.top-bar-black {
	border: 0;
	background: $top-bar-black-bg;
	color: $top-bar-black-color;
}

.top-bar-black a {
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	color: $top-bar-black-color;
}

.top-bar-black a:hover,
.top-bar-black a:focus {
	color: rgba($top-bar-black-color, 0.85);
}

.top-bar-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.top-bar-list > li {
	display: inline-block;
}

.top-bar-list > li:not(:first-child) {
	margin-left: 10px;
}

.top-bar-list.list-icons > li .fa,
.top-bar-list.list-icons > li .icons {
	margin-right: 5px;
}

.list-dividers li:not(:last-child) a {
	border-right: 1px solid whitesmoke;
	padding-right: 10px;
}


.top-bar-black .list-dividers li:not(:last-child) a {
	border-right: 1px solid $top-bar-black-list-dividers-border-color;
}

@media (max-width: $screen-sm-max) {
	.top-bar .container {
		width: 100%;
	}
}


/*------------------------------------------------------------------
[Pricing]
*/

$pricing-table-bg: #fff;
$pricing-table-box-shadow-color: #eee;
$pricing-price-border-color: #f5f5f5;
$pricing-price-price-unit-color: #222;
$pricing-features-ul-li-border-color: #f5f5f5;
$pricing-features-ul-li-background: #f8f8f8;

.pricing-table {
	background: $pricing-table-bg;
	box-shadow: 0 0 1px 1px $pricing-table-box-shadow-color;
	position: relative;
	text-align: center;
	margin: 10px 0 0;
	&.best-value {
		z-index: 1;
		margin: 0;
		.pricing-title {
			padding: 30px 0;
		}
		.pricing-action {
			padding: 30px 0;
		}
	}
}
.pricing-title {
	padding: 25px 0;
	h6 {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		margin: 0;
	}
}
.pricing-action {
	padding: 25px 0;
}
.pricing-price {
	border-top: 1px solid $pricing-price-border-color;
	border-bottom: 1px solid $pricing-price-border-color;
	padding: 20px 0;
	.price-unit {
		text-transform: uppercase;
		font-size: 54px;
		color: $pricing-price-price-unit-color;
	}
}
@media(max-width: $screen-xs-max) {
	.pricing-table.best-value {
		margin: 10px 0 0;
	}
}
.price-unit > span {
	vertical-align: top;
	letter-spacing: 2px;
	line-height: 3.5;
	font-size: 22px;
}
.pricing-features ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		border-bottom: 1px solid $pricing-features-ul-li-border-color;
		padding: 8px 0;
		&:nth-child(odd) {
			background: $pricing-features-ul-li-background;
		}
	}
}
/*------------------------------------------------------------------
[Pagination]
*/

$pagination-li-a-color: #777;
$pagination-li-a-color-hover: $brand-primary;

$pagination-li-a-color-active: $brand-primary;
$pagination-li-a-color-active-hover: $brand-primary;

$pagination-border-color: #ddd;

.pagination > li {
	> a {
		color: $pagination-li-a-color;
		border-color: $pagination-border-color;
		&:hover {
			color: $pure-white;
			background: $pagination-li-a-color-hover;
			border-color: $pagination-li-a-color-hover;
		}
		&:focus {
			color: $pure-white;
			background: $pagination-li-a-color-hover;
			border-color: $pagination-li-a-color-hover;
		}
	}
	> span {
		color: $pagination-li-a-color;
		border-color: $pagination-border-color;
		&:hover {
			color: $pure-white;
			background: $pagination-li-a-color-hover;
			border-color: $pagination-li-a-color-hover;
		}
		&:focus {
			color: $pure-white;
			background: $pagination-li-a-color-hover;
			border-color: $pagination-li-a-color-hover;
		}
	}
	&.active {
		> a {
			background: $pagination-li-a-color-active;
			border-color: $pagination-li-a-color-active;
			&:hover {
				background: $pagination-li-a-color-active-hover;
				border-color: $pagination-li-a-color-active-hover;
			}
			&:focus {
				background: $pagination-li-a-color-active-hover;
				border-color: $pagination-li-a-color-active-hover;
			}
		}
		> span {
			background: $pagination-li-a-color-active;
			border-color: $pagination-li-a-color-active;
			&:hover {
				background: $pagination-li-a-color-active-hover;
				border-color: $pagination-li-a-color-active-hover;
			}
			&:focus {
				background: $pagination-li-a-color-active-hover;
				border-color: $pagination-li-a-color-active-hover;
			}
		}
	}
	&.disabled {
		> a {
			border-color: $pagination-border-color;
			&:hover {
				border-color: $pagination-border-color;
			}
			&:focus {
				border-color: $pagination-border-color;
			}
		}
		> span {
			border-color: $pagination-border-color;
			&:hover {
				border-color: $pagination-border-color;
			}
			&:focus {
				border-color: $pagination-border-color;
			}
		}
	}
}


/*------------------------------------------------------------------
[Carousel]
*/

$prev-next-color: #222;
$prev-next-bg: $pure-white;
$prev-next-color-white: $pure-white;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

%prevnext {
	position: absolute;
	top: 50%;
	margin: -23px 0 0;
	font-size: 18px;
	color: $prev-next-color;
	background: $prev-next-bg;
	height: 46px;
	width: 46px;
	line-height: 46px;
	text-align: center;
	border-radius: $brand-sitewide-radius;
	opacity: 0;
	-webkit-transition: $main-custom-transition;
	transition: $main-custom-transition;
}

.owl-prev {
	@extend %prevnext;
	left: 23px;
}
.owl-next {
	@extend %prevnext;
	right: 23px;
}
.owl-pagination {
	text-align: center;
	margin: 10px 0 0;
	+ .owl-buttons {
		.owl-prev {
			margin-top: -35.5px;
		}
		.owl-next {
			margin-top: -35.5px;
		}
	}
}
.owl-page {
	display: inline-block;
	position: relative;
	margin: 0 5px;
	span {
		background: $brand-primary;
	}
	&.active span {
		background: transparent;
		box-shadow: 0 0 0 3px $brand-primary;
	}
}
.carousel-controls-white {
	.owl-prev {
		color: $prev-next-color-white;
	}
	.owl-next {
		color: $prev-next-color-white;
	}
}
.image-slider {
	padding: 0;
	margin: 0;
	.owl-item {
		padding: 0;
		margin: 0;
	}
	.owl-pagination {
		position: absolute;
		bottom: 10px;
		width: 100%;
	}
	.owl-page {
		span {
			background: $pure-white;
		}
		&.active span {
			background: transparent;
			box-shadow: 0 0 0 3px $pure-white;
		}
	}
	.owl-buttons {
		.owl-prev {
			margin-top: -23px;
		}
		.owl-next {
			margin-top: -23px;
		}
	}
}
.images-carousel {
	width: auto;
	margin: 0 -5px;
	.owl-item {
		padding: 0 5px;
	}
}
.box-carousel {
	width: auto;
	margin: 0 -15px;
	.owl-item {
		padding: 0 15px;
	}
}
.owl-carousel:hover {
	.owl-prev {
		opacity: 0.6;
	}
	.owl-next {
		opacity: 0.6;
	}
}
.carousel-pagination-white .owl-page {
	span {
		background: $pure-white;
	}
	&.active span {
		background: transparent;
		box-shadow: 0 0 0 3px $pure-white;
	}
}